import React from "react";
import "./App.css";
import ResetPassword from "./containers/ResetPassword";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <div>
        <ResetPassword></ResetPassword>
      </div>
    </div>
  );
}

export default App;
