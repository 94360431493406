import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { BsCheck } from "react-icons/bs";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import "./ResetPassword.css";
import logo from "../logo.png";
import axios from "axios";
import { API_URL } from "../api/graphql/instance";

export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    id: "",
    token: "",
    password: "",
    confirmPassword: "",
  });
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  async function verifyParams() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const id = params.get("id");
    const token = params.get("token");
    // @ts-ignore: Object is possibly 'null
    if (token.length <= 0) {
      alert("Invalid Token.");
      return;
    }
    // @ts-ignore: Object is possibly 'null
    if (id.length <= 0) {
      alert("Invalid user ID.");
      return;
    }
    console.log("The Token ", token);
    console.log("The ID ", id);

    fields.id = id;
    fields.token = token;

    try {
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    verifyParams();
  });

  function validateResetForm() {
    return (
      fields.id.length > 0 &&
      fields.token.length > 0 &&
      fields.password.length >= 8 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleConfirmClick(event: { preventDefault: () => void }) {
    event.preventDefault();
    setIsConfirming(true);
    try {
      let data = [fields].map((field: any) => ({
        id: field.id,
        token: field.token,
        password: field.password,
      }))[0];

      console.log("THE DATA: ", data);

      axios({
        url: `${API_URL}/api/users/password-recovery`,
        method: "POST",
        data: data,
      })
        .then((res) => {
          console.log("RES: ", res);
          setConfirmed(true);
        })
        .catch((err) => {
          console.log("ERR:", err);
        });
    } catch (error) {
      alert(error);
      setIsConfirming(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form style={{ margin: 30 }}>
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{ marginBottom: 20 }}
        />
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            size="lg"
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group className="mb-6" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            size="lg"
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
        </Form.Group>
        {fields.password.length < 8 && (
          <>
            <div className="error">
              <span>Password must be at least 8 characters long.</span>
            </div>
          </>
        )}
        {fields.password.length !== fields.confirmPassword.length && (
          <>
            <div className="error">
              <span>Passwords must match.</span>
            </div>
          </>
        )}
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isConfirming}
          disabled={!validateResetForm()}
          onClick={handleConfirmClick}
        >
          Reset Password
        </LoaderButton>
      </Form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <p>
          <BsCheck size={16} /> Your password has been reset.
        </p>
        <p>
          Go back to the Charitable List app to log in with your new
          credentials.
        </p>
      </div>
    );
  }

  return (
    <div className="ResetPassword">
      {!confirmed ? renderConfirmationForm() : renderSuccessMessage()}
    </div>
  );
}
