import { ApolloClient, InMemoryCache } from "@apollo/client";
// import { API_URL } from '@env';
export const API_URL = "https://api.charitablelist.org";
// Initialize Apollo Client
const client = new ApolloClient({
  uri: `${API_URL}/graphql`,
  cache: new InMemoryCache(),
});

export default client;
