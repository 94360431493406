import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(e, name = '') {
      if (e instanceof Object && e.target instanceof Object) {
        // Safe to assume it was an event passed in
        setValues({ ...fields, [e.target.id]: e.target.value });
      } else {
        // Assume the value was passed in directly
        setValues({ ...fields, [name]: e });
      }
    }]
}
